import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgHomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="24px" viewBox="0 0 36 36" width="24px" {...props}>
    <path
      d="M13.702 31.16v-4.587c0-1.17.956-2.12 2.136-2.12h4.31c.567 0 1.11.224 1.51.621.401.398.626.937.626 1.5v4.586a1.82 1.82 0 0 0 .535 1.3c.345.346.815.54 1.305.54h2.942a5.2 5.2 0 0 0 3.665-1.499 5.1 5.1 0 0 0 1.519-3.634V14.8a3.7 3.7 0 0 0-1.343-2.853L20.9 4.014a4.646 4.646 0 0 0-5.923.106L5.2 11.947A3.71 3.71 0 0 0 3.75 14.8v13.053C3.75 30.696 6.071 33 8.934 33h2.874a1.847 1.847 0 0 0 1.854-1.827z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgHomeIcon);
export default Memo;
